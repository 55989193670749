// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-basicpost-jsx": () => import("./../src/templates/basicpost.jsx" /* webpackChunkName: "component---src-templates-basicpost-jsx" */),
  "component---src-templates-basicbook-jsx": () => import("./../src/templates/basicbook.jsx" /* webpackChunkName: "component---src-templates-basicbook-jsx" */),
  "component---src-pages-about-jsx": () => import("./../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-books-jsx": () => import("./../src/pages/books.jsx" /* webpackChunkName: "component---src-pages-books-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-posts-jsx": () => import("./../src/pages/posts.jsx" /* webpackChunkName: "component---src-pages-posts-jsx" */)
}

